import React, { useState } from "react"
import Layout from "../components/layout"
import Footer from "../components/layout/newFooter"
import inetevol from "../assets/images/InternetEvolutionTapestry.jpg";

const Mural = () => {
  // State to manage if the image is magnified
  const [isMagnified, setIsMagnified] = useState(false)

  // Function to toggle magnification
  const toggleMagnification = () => {
    setIsMagnified(!isMagnified)
  }

  return (
    <Layout>
      <h1 style={{ margin: "30px", textAlign: "center" }}>
        Internet Evolution Tapestry
      </h1>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "20px",
        }}
      >
        <div // Image container
          style={{
            maxWidth: "100%", // Ensure the container does not exceed the viewport width
            maxHeight: "90vh", // Optional: Limit the container height to ensure it's within the viewport
            cursor: "pointer", // Change cursor to indicate clickable
          }}
        >
          <a href={inetevol} target="_blank" rel="noopener noreferrer">
            <img
              src={inetevol}
              alt="Internet Evolution Tapestry"
              style={{
                width: "100%", // Ensure the image scales to the container's width
                height: "auto", // Maintain aspect ratio
                objectFit: "cover", // Cover ensures the image will fill the container
              }}
            />
          </a>
        </div>
        <div
          style={{
            maxWidth: "800px",
            width: "100%",
            textAlign: "justify",
            padding: "20px",
          }}
        >
 <div style={{ marginBottom: '1.5em' }}>
  The Internet Evolution Tapestry is a project by visual journalist Eileen Clegg in collaboration with Dr. Vinton G. Cerf, Father of the Internet with fellow TCP/IP inventor Dr. Robert E. Kahn, and many other Internet pioneers. It is a 4 by 12-foot mural that was exhibited during celebrations of the Internet’s 50th Anniversary, May 19 and 20, 2024 in Palo Alto California. The Tapestry provided a conversation piece for attendees and a backdrop for Dr. Cerf to tell stories, share perspectives and converse with colleagues about the history and possible futures of the Internet.{" "}
</div>
<div style={{ marginBottom: '1.5em' }}>
  The Internet Evolution Tapestry is a pictorial mapping of events from pre-Internet seeds of the ARPAnet to the present day Internet that has 5.3-billion users and impacts almost every aspect of life today. The wheel-shaped sun represents the TCP/IP (Transmission Control Protocol/Internet Protocol) rules, published in IEEE Communications by Drs. Cerf and Kahn in May, 1974, that launched the Internet, a network of networks designed to grow and scale collaboratively.{" "}
</div>
<div>
  Eileen was assisted in the drawing by Ellen Lovelidge and other Tapestry colleagues. Tapestry is a company that is automating the visual capture of conversations to humanize online meetings. The Internet Evolution Tapestry was created by hand with markers and pastels, the traditional media for visual communication. It is an evolving project. Above is Draft 2.5, incorporating some of the ideas contributed during the celebrations. We expect Draft 3 to be 3-dimensional
</div>
        </div>
        </div>
      <Footer />
    </Layout>
  )
}

export default Mural